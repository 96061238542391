import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import TakeoutButton from './TakeoutButton'
import DineinButton from './DineinButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          image={`url(${mobileHero}) bottom/ cover no-repeat`}
          column
          justifyStart
          alignCenter
          textCenter
        >
          <CFImage
            mt="20px"
            w="90%"
            src={mobileHeroText}
            alt="O Sushi hero text"
            zIndex={98}
          />
          <CFView pulsate mt="15px">
            <OrderPickupButton />
          </CFView>
          <CFView mt="5px">
            <TakeoutButton />
          </CFView>
          <CFView mt="5px">
            <DineinButton />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="95vh"
          maxHeight="670px"
          w="100%"
          image={`url(${hero}) 45% 0 / cover no-repeat`}
          zIndex={90}
          column
          justifyCenter
          alignStart
        >
          <CFView column center ml="3%">
            <CFImage
              w="37vw"
              minWidth="480px"
              maxWidth="550px"
              src={heroText}
              alt="O Sushi hero text"
              zIndex={98}
            />
            <CFView pulsate mt="25px">
              <OrderPickupButton />
            </CFView>
            <CFView mt="10px">
              <TakeoutButton />
            </CFView>
            <CFView mt="10px">
              <DineinButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
