import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { dineButton, dineMenu } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getPdfMenuUrl}>
      {pdfMenuUrl => (
        <CFView>
          <MobileScreen>
            <a href={dineMenu} target="_blank">
              <CFView hover>
                <CFImage
                  src={dineButton}
                  w="70%"
                  maxWidth="=250px"
                  alt="About"
                />
              </CFView>
            </a>
          </MobileScreen>
          <DefaultScreen>
            <a href={dineMenu} target="_blank">
              <CFView hover>
                <CFImage
                  src={dineButton}
                  w="25vw"
                  maxWidth="300px"
                  alt="About"
                />
              </CFView>
            </a>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
